.editor-container {
  @extend .position-relative;

  .toolbar {
    @extend .d-flex;
    @extend .mb-0;
    @extend .bg-light-subtle;
    @extend .p-0;
    @extend .rounded-1;
    @extend .align-middle;

    button.toolbar-item {
      @extend .border-0;
      @extend .d-flex;
      @extend .bg-primary-subtle;
      @extend .rounded-1;
      @extend .p-1;
      cursor: pointer;
      @extend .align-middle;
      @extend .user-select-none;

      .text {
        @extend .d-flex;
        width: 200px;
        @extend .align-middle;
        @extend .overflow-hidden;
        width: 70px;
        height: 20px;
        @extend .user-select-none;
      }

      svg {
        @extend .d-flex;
        width: 20px;
        height: 20px;
        @extend .user-select-none;
        background-size: contain;
      }

      svg.format {
        background-size: contain;
        @extend .d-inline-block;
        height: 18px;
        width: 18px;
        @extend .mt-1;
        vertical-align: -0.25em;
        @extend .d-flex;
        opacity: 0.6;
      }

      &:hover:not([disabled]) {
        @extend .bg-success-subtle;
      }

      &:disabled {
        cursor: not-allowed;

        svg.format {
          opacity: 0.2;
        }
      }

      &.active {
        @extend .bg-secondary;
        @extend .text-light;

        svg {
          opacity: 1;
        }
      }

      &.spaced {
        @extend .me-1;
      }
    }
  }

  .editor-inner {
    @extend .position-relative;

    .shxeditor-editor-wrapper {
      .shxeditor-richtext-editor {
        .editor-scroller {
          max-height: 500px;
          overflow: auto;

          .editor {
            .editor-input {
              resize: none;
              caret-color: rgb(5, 5, 5);
              @extend .position-relative;
              tab-size: 1;
              outline: 0;
              @extend .p-0;
              @extend .pt-1;
              caret-color: #444;
            }

            .editor-placeholder {
              @extend .text-light;
              @extend .overflow-hidden;
              @extend .position-absolute;
              @extend .text-truncate;
              top: 0px;
              left: 0px;
              @extend .user-select-none;
              @extend .d-inline-block;
              @extend .pe-none;
            }
          }
        }
      }
    }
  }
}

.internal-editor-container {
  @extend .position-relative;

  .internal-editor-inner {
    @extend .position-relative;

    .shxeditor-editor-internal-wrapper {
      .shx-internal-plaintext-editor {
        .internal-editor-scroller {
          max-height: 500px;
          overflow: auto;

          .internal-editor {
            .editor-input {
              resize: none;
              caret-color: rgb(5, 5, 5);
              @extend .position-relative;
              tab-size: 1;
              outline: 0;
              @extend .p-0;
              @extend .pt-1;
              caret-color: #444;
            }

            .editor-placeholder {
              @extend .text-light;
              @extend .overflow-hidden;
              @extend .position-absolute;
              @extend .text-truncate;
              top: 0px;
              left: 0px;
              @extend .user-select-none;
              @extend .d-inline-block;
              @extend .pe-none;
            }
          }
        }
      }
    }
  }
}

.editor-container-clickable {
  cursor: pointer;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  padding: 1px 0.25rem;
  @extend .monospace-text;
}

.editor-text-highlight {
  background-color: yellow;
}

.editor-link {
  @extend .text-secondary;
}

.editor-link[contenteditable='false'] {
  cursor: pointer;
}

.editor-heading-h1,
.editor-heading-h2,
.editor-heading-h3,
.editor-heading-h4,
.editor-heading-h5,
.editor-heading-h6 {
  text-transform: none;
}

.editor-listitem-checked,
.editor-listitem-unchecked {
  @extend .position-relative;
  list-style-type: none;
  outline: none;
  // margin-left: 8px;
  // margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.editor-listitem-checked {
  @extend .text-decoration-line-through;
}

.editor-listitem-checked:before,
.editor-listitem-unchecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  @extend .d-block;
  background-size: cover;
  @extend .position-absolute;
}

.editor-listitem-checked:focus:before,
.editor-listitem-unchecked:focus:before {
  @extend .shadow;
  @extend .rounded;
}

.editor-listitem-unchecked:before {
  @extend .border;
  @extend .border-1;
  @extend .border-secondary;
}

.editor-listitem-checked:before {
  @extend .border;
  @extend .border-1;
  @extend .border-primary;
  @extend .bg-secondary-subtle;
}

.editor-listitem-checked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}

.editor-nested-listitem {
  list-style-type: none;
}

.editor-nested-listitem:before,
.editor-nested-listitem:after {
  list-style-type: none;
}

.link-editor {
  // @extend .d-flex;
  @extend .position-absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  opacity: 0;
  @extend .bg-white;
  @extend .shadow;
  @extend .rounded;
  transition: opacity 0.5s;
  will-change: transform;
}

.floating-menu-popup {
  @extend .d-flex;
  @extend .bg-white;
  padding: 4px;
  vertical-align: middle;
  @extend .position-absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  @extend .shadow;
  @extend .rounded;
  transition: opacity 0.5s;
  height: 35px;
  will-change: transform;
}

.editor-code {
  @extend .bg-light-subtle;
  @extend .monospace-text;
  @extend .d-block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  @extend .position-relative;
  tab-size: 2;
}

.editor-code:before {
  content: attr(data-gutter);
  @extend .position-absolute;
  @extend .bg-secondary-subtle;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  @extend .text-dark;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor-quote {
  @extend .blockquote;
  @extend .border-start;
  @extend .border-3;
  @extend .ps-2;
  @extend .border-secondary;
  font-size: 1rem;

  .editor-quote-citation {
    @extend .text-end;
    @extend .fst-italic;
  }
}

.editor-footnote-identifier {
  @extend .fw-bold;

  a {
    @extend .text-decoration-none;
  }
}

.editor-footnote-container {
  @extend .bg-light-subtle;
  @extend .border-top;
  @extend .border-1;
  @extend .border-light;
  @extend .p-2;
  @extend .pb-0;

  /* placeholder for future styles 
  .editor-footnote-container-title {
  } */

  .editor-footnote-definition {
    .editor-footnote-definition-link {
      @extend .text-decoration-none;
      @extend .fw-bold;

      a {
        @extend .text-decoration-none;
      }
    }

    p {
      @extend .d-inline;
    }
  }
}
